.media-slider-middle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
}

.media-slider-middle > .btn > svg {
  cursor: pointer;
}

.media-slider-middle > .scrolling {
  width: 100%;
  height: 450px;
  display: flex;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-right: 10px;
  scroll-behavior: smooth;
  transition: scroll-left 0.2s ease;
}

.media-slider-middle > .scrolling {
  scrollbar-width: none;
}

.media-slider-middle > .scrolling::-webkit-scrollbar {
  display: none;
}

.media-slider-middle > .scrolling > .box {
  width: fit-content;
  height: 400px;
  flex-shrink: 0;
  overflow: hidden;
}

.media-slider-middle > .scrolling > .box > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media screen and (max-width: 896px) {
  .media-slider-middle > .scrolling > .box {
    /* width: 80%; */
    padding: 0%;
  }

  .media-slider-middle > .scrolling > .box > img {
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  }

  .media-slider-middle > .scrolling {
    width: calc(100% + 60px);
    height: fit-content;
  }
}
