* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Campton";
  src: url("../../../public/Campton-Light.ttf");
}
@font-face {
  font-family: "Colgent";
  src: url("../../../public/Colgent-Italic.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.festival-images {
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.festival-images > .wrap {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 120px;
  margin-bottom: 30px;
}

.festival-images > .wrap > .back-button {
  display: none;
}

.festival-images > .wrap > h1 {
  color: rgba(32, 32, 32, 0.5);
  font-family: Montserrat;
  font-style: italic;
  font-size: 58px;
  font-weight: 500;
}

.festival-images > .image-card > .loading-card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 20px;
}

.festival-images > .image-card {
  width: 100%;
  display: flex;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-left: 80px;
  scroll-behavior: smooth;
  transition: scroll-left 0.2s ease;
}

.festival-images > .btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 40px 80px;
  margin-bottom: 70px;
}

.festival-images > .btn > svg {
  cursor: pointer;
}

.festival-images > .image-card {
  scrollbar-width: none;
}

.card-gallery {
  width: 100%;
  height: 400px;
}

.card-gallery > img {
  height: 100%;
  object-fit: fill;
}

.image-overlay {
  position: fixed;
  top: 0%;
  width: 100%;
  height: 100%;
  background: rgba(18, 18, 18, 0.8);
  z-index: 101;
  display: flex;
  justify-content: center;
  justify-content: center;
  padding: 80px;
}

.image-overlay > .close-button {
  position: absolute;
  right: 40px;
  cursor: pointer;
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  color: #ffffff;
}

.image-overlay > .overlay-content {
  width: 100%;
  height: 595px;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  scroll-behavior: smooth;
  transition: scroll-left 0.2s ease;
}

.image-overlay > .overlay-content > .overlay-image {
  width: 80%;
  height: 100%;
  object-fit: contain;
}

.image-overlay > .overlay-content > .prev-button,
.image-overlay > .overlay-content > .next-button {
  width: 60px;
  height: 60px;
  color: #ffffff;
  cursor: pointer;
}

@media screen and (max-width: 896px) {
  .festival-images > .image-card {
    flex-direction: column;
    padding: 20px;
  }

  .festival-images > .btn {
    display: none;
  }

  .card-gallery > img {
    width: 100%;
    border-radius: 10px;
    object-fit: contain;
  }

  .card-gallery {
    height: auto;
  }

  .image-overlay {
    padding: 20px;
  }

  .image-overlay > .close-button {
    left: 20px;
    z-index: 3;
    width: 30px;
    height: 30px;
  }

  .image-overlay > .overlay-content > .overlay-image {
    width: 100%;
    position: relative;
  }

  .image-overlay > .overlay-content > .prev-button {
    position: absolute;
    left: 0%;
    z-index: 2;
    width: 30px;
    height: 30px;
  }
  .image-overlay > .overlay-content > .next-button {
    position: absolute;
    right: 0%;
    z-index: 2;
    width: 30px;
    height: 30px;
  }

  .festival-images > .wrap > .back-button {
    display: block;
    color: rgba(32, 32, 32, 0.5);
  }

  .festival-images > .wrap {
    width: 100%;
    padding-left: 20px;
    gap: 10px;
  }

  .festival-images > .wrap > h1 {
    font-size: 14px;
  }

  .festival-images > .image-card > .loading-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
