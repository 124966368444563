* {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }
  
  @font-face {
    font-family: "Campton";
    src: url("../../../public/Campton-Light.ttf");
  }
  @font-face {
    font-family: "Colgent";
    src: url("../../../public/Colgent-Italic.ttf");
  }
  
  @font-face {
    font-family: "Montserrat";
    src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  }
  
  .infrastucture-detail {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 50px 80px;
  }
  
  .infrastucture-detail > img {
    width: 100%;
    height: 600px;
    object-fit: cover;
  }
  
  .infrastucture-detail > .wrapper {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 45px;
    margin-top: 30px;
    padding: 0px 5px 0px 297px;
  }
  
  .infrastucture-detail > .wrapper > .card-1 {
    width: 65%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .infrastucture-detail > .wrapper > .card-1 > h3 {
    color: #000;
    font-family: Campton;
    font-size: 30px;
    font-weight: 500;
    line-height: 130.4%;
    margin-top: 20px;
  }
  .infrastucture-detail > .wrapper > .card-1 > p {
    color: #202020;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130.2%;
    margin: 10px 0px;
  }
  
  .infrastructure-detail-line {
    width: 630px;
    height: 5px;
    background: #121212;
  }
  
  .infrastucture-detail > .wrapper > .card-2 {
    width: 290px;
    height: 290px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 200px;
    border: 5.5px solid #000;
    background: #fff;
    margin-top: 50%;
  }
  
  .infrastructure-detail-con1 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 20px;
  }
  
  .infrastructure-detail-con1 > img:nth-child(1) {
      width: 60%;
      height: 400px;
      object-fit: cover;
  }
  .infrastructure-detail-con1 > img:nth-child(2) {
      width: 40%;
      height: 330px;
      object-fit: cover;
  }
  
  .infrastructure-detail-con2 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      gap: 20px;
      margin-top: 20px;
  }
  
  .infrastructure-detail-con2 > img {
      width: 100%;
      height: 400px;
      object-fit: cover;
  }
  
  .infrastructure-detail-con3 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      margin: 20px;
  }
  
  .infrastructure-detail-con3 > img:nth-child(1) {
      width: 50%;
      height: 400px;
      object-fit: cover;
  }
  .infrastructure-detail-con3 > img:nth-child(2) {
      width: 50%;
      height: 400px;
      object-fit: cover;
  }
  
  @media screen and (max-width:896px) {
      .infrastucture-detail {
          padding: 0px;
      }
  
      .infrastucture-detail > .wrapper {
          padding: 10px;
      }
  
      .infrastucture-detail > .wrapper > .card-1 {
          width: 100%;
      }
  
      .infrastructure-detail-line {
          width: 100%;
      }
  
      .infrastructure-detail-con2 {
          padding: 10px;
      }
  
      .infrastructure-detail-con1 {
          flex-direction: column;
          padding: 10px;
      }
  
      .infrastructure-detail-con1 > img:nth-child(1) {
          width: 100%;
      }
      .infrastructure-detail-con1 > img:nth-child(2) {
          width: 100%;
      }
  
      .infrastucture-detail > .wrapper > .card-2 {
          display: none;
      }
  
      .infrastructure-detail-con3 {
          flex-direction: column;
          padding: 10px;
      }
  
      .infrastructure-detail-con3 > img:nth-child(1) {
          width: 100%;
      }
      .infrastructure-detail-con3 > img:nth-child(2) {
          width: 100%;
      }

      .infrastucture-detail > img {
        width: 100%;
        max-height: 200px;

      }

  }
  