* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Campton";
  src: url("../../../public/Campton-Light.ttf");
}
@font-face {
  font-family: "Colgent";
  src: url("../../../public/Colgent-Italic.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.about-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.about-container > .hero-container {
  width: 100%;
  height: 615px;
}

.about-container > .hero-container > .overley {
  width: 100%;
  height: 100%;
  background-image: url("https://i.ibb.co/M5yWsRt/DSC00123-2-2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.about-container > .hero-container > .overley > .card {
  width: 100%;
  height: 100%;
  background: rgba(18, 18, 18, 0.4);
  padding: 0px 80px;
}

.about-container > .hero-container > .overley > .card > p {
  display: flex;
  align-items: flex-end;
  padding: 30px;
  color: #fff;
  font-family: Montserrat;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: -0.05px;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 896px) {

  .about-container > .hero-container > .overley > .card{
    padding: 0px 0px;
  }

  .about-container > .hero-container > .overley > .card > p {
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px;
    letter-spacing: -0.028px;
    padding: 16px;
  }
}

.about-container > .banner {
  width: 100%;
  padding: 0px 80px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 130px;
  margin-bottom: 150px;
}

.about-container > .banner > h3 {
  color: var(--green, #2b9a5f);
  font-family: Campton;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}

.about-container > .banner > .box1 {
  width: 100%;
  display: flex;
  gap: 50px;
}

.about-container > .banner > .box1 > .wrap {
  width: 786px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.about-container > .banner > .box1 > .wrap > p {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.about-container > .banner > .box1 > img {
  width: 444px;
}

.about-container > .banner > .box2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.about-container > .banner > .box2 > p {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.about-container > .banner > .box3 {
  width: 100%;
  display: flex;
  gap: 50px;
}

.about-container > .banner > .box3 > img {
  width: 444px;
}

.about-container > .banner > .box3 > .wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 70px;
}

.about-container > .banner > .box3 > .wrap > p {
  color: #000;
  text-align: justify;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

@media screen and (max-width: 896px) {
  .about-container > .banner {
    padding: 16px;
    margin: 50px 0px;
  }

  .about-container > .banner > .box1 {
    flex-direction: column;
  }

  .about-container > .banner > .box1 > .wrap {
    width: 100%;
  }

  .about-container > .banner > .box1 > .wrap > p {
    color: #000;
    text-align: justify;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 132.5%;
  }
  .about-container > .banner > .box1 > img {
    width: 100%;
  }

  .about-container > .banner > .box2 > p {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 132%;
  }
  .about-container > .banner > .box3 > img {
    width: 100%;
    height: fit-content;
  }

  .about-container > .banner > .box3 {
    flex-direction: column;
  }

  .about-container > .banner > .box3 > .wrap {
    gap: 30px;
  }
  .about-container > .banner > .box3 > .wrap > p {
    color: #000;
    text-align: justify;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 132%;
  }
}

.about-container > .politics-container {
  width: 100%;
  padding: 150px 80px;
  display: flex;
  gap: 95px;
  background: #1b3126;
}

.about-container > .politics-container > h3 {
  color: var(--yellow, #fecb00);
  font-family: Colgent;
  font-size: 52px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.about-container > .politics-container > span {
  color: var(--yellow, #fecb00);
  font-family: Colgent;
  font-size: 52px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
}

.about-container > .politics-container > .wrap {
  width: 100%;
  display: flex;
  gap: 50px;
  flex-direction: column;
}

.about-container > .politics-container > .wrap > .inner {
  width: 100%;
  display: flex;
  gap: 50px;
}

.about-container > .politics-container > .wrap > .inner > .box1,
.about-container > .politics-container > .wrap > .inner > .box2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.about-container > .politics-container > .wrap > .inner > .box1 > p,
.about-container > .politics-container > .wrap > .inner > .box2 > p {
  color: #fff;
  text-align: justify;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.about-container > .politics-container > .wrap > .img {
  width: 100%;
  height: 600px;
  background-image: url("https://i.ibb.co/yf2D6kD/Dapo-Abiodun-416-1.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100%;
}

.about-container > .politics-container > .wrap > .below {
  width: 100%;
  display: flex;
  gap: 50px;
}

.about-container > .politics-container > .wrap > .below > .box1,
.about-container > .politics-container > .wrap > .below > .box2 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;
}

.about-container > .politics-container > .wrap > .below > .box1 > p,
.about-container > .politics-container > .wrap > .below > .box2 > p {
  color: #fff;
  text-align: justify;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

@media screen and (max-width: 896px) {
  .about-container > .politics-container {
    flex-direction: column;
    padding: 50px 16px;
    gap: 25px;
  }

  .about-container > .politics-container > h3 {
    color: var(--yellow, #fecb00);
    font-family: Colgent;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .about-container > .politics-container > h3 > span {
    color: var(--yellow, #fecb00);
    font-family: Colgent;
    font-size: 24px;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
  }

  .about-container > .politics-container > .wrap {
    gap: 25px;
  }

  .about-container > .politics-container > .wrap > .inner {
    flex-direction: column;
    gap: 25px;
  }

  .about-container > .politics-container > .wrap > .inner > .box1,
  .about-container > .politics-container > .wrap > .inner > .box2 {
    gap: 25px;
  }

  .about-container > .politics-container > .wrap > .img {
    height: 280px;
  }
  .about-container > .politics-container > .wrap > .below {
    flex-direction: column;
    gap: 25px;
  }

  .about-container > .politics-container > .wrap > .below > .box1,
  .about-container > .politics-container > .wrap > .below > .box2 {
    gap: 25px;
  }

  .about-container > .politics-container > .wrap > .below > .box1 > p,
  .about-container > .politics-container > .wrap > .below > .box2 > p {
    color: #fff;
    text-align: justify;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 132.2%;
  }
}

.about-container > .masterclass {
  width: 100%;
  height: 487px;
  display: flex;
  padding: 0px 114px 0px 130px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  background: #fff;
}

.about-container > .masterclass > .box-1 {
  width: 50%;
  border-right: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-container > .masterclass > .box-2 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-container > .masterclass > .box-2 > p {
  color: #202020;
  font-family: Campton;
  font-size: 33px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.66px;
  padding-left: 100px;
}

@media screen and (max-width: 896px) {
  .about-container > .masterclass {
    padding: 0px 16px;
  }
  .about-container > .masterclass > .box-1 > svg {
    width: 98.313px;
    height: 45.21px;
    flex-shrink: 0;
  }
  .about-container > .masterclass > .box-2 {
    padding: 16px;
  }
  .about-container > .masterclass > .box-2 > p {
    color: #202020;
    font-family: Campton;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.26px;
    padding: 0%;
  }
}

.about-container > .award {
  width: 100%;
  display: flex;
  padding: 53px 80px 45px 80px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  background: #fff;
}

.about-container > .award > img {
  width: 100%;
}

@media screen and (max-width: 896px) {
  .about-container > .award {
    padding: 20px 16px;
  }
}

.about-container > .quote {
  width: 100%;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.about-container > .quote > p:nth-child(1) {
  color: #202020;
  text-align: center;
  font-family: Campton;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 0.52px;
  width: 1041px;
}

.about-container > .quote > p:nth-child(2) {
  width: 1041px;
  color: var(--green, #2b9a5f);
  font-family: Campton;
  font-size: 17px;
  font-style: italic;
  font-weight: 400;
  line-height: 119.403%;
  letter-spacing: 0.34px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 896px) {
  .about-container > .quote {
    padding: 50px 16px;
    height: fit-content;
  }

  .about-container > .quote > p:nth-child(1) {
    width: 100%;
    color: #202020;
    text-align: center;
    font-family: Campton;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.22px;
  }

  .about-container > .quote > p:nth-child(2) {
    width: 100%;
    color: var(--green, #2b9a5f);
    font-family: Campton;
    font-size: 9px;
    font-style: italic;
    font-weight: 400;
    line-height: 119.403%;
    letter-spacing: 0.18px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.about-container > .drag {
  width: 100%;
  padding-left: 80px;
  margin-bottom: 119px;
}

@media screen and (max-width: 896px) {
  .about-container > .drag {
    padding: 16px;
  }
}
