* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Campton";
  src: url("../../../public/Campton-Light.ttf");
}
@font-face {
  font-family: "Colgent";
  src: url("../../../public/Colgent-Italic.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.new-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.new-container > .heading {
  width: 100%;
  height: 120px;
  padding: 80px;
  margin: 50px 0px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.new-container > .heading > h2 {
  color: #202020;
  font-family: Campton;
  font-size: 93px;
  font-weight: 500;
  border-bottom: 1px solid;
}

.new-container > .latest {
  width: 100%;
  display: flex;
  padding: 40px 80px;
  gap: 20px;
  text-decoration: none;
}

.new-container > .latest > img {
  width: 812px;
  height: 406px;
  object-fit: cover;
}

.loading {
  color: rgba(32, 32, 32, 0.5);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 132.1%;
}

.error {
  color: rgb(239, 72, 72);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 132.1%;
}

.new-container > .latest > .box {
  width: 408px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.new-container > .latest > .box > h3 {
  color: #000;
  font-family: Campton;
  font-size: 30px;
  font-weight: 500;
}

.new-container > .latest > .box > p {
  color: #000;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}



.new-container > .latest > .box > button {
  background-color: transparent;
  color: #1b3126;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  width: fit-content;
  border: none;
  outline: none;
}

.new-container > .latest > .box > span {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.new-container > .news-card {
  width: 100%;
  padding: 80px;
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 30px 60px;
}

.new-container > .news-card > .card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-decoration: none;
}

.new-container > .news-card > .card > button {
  background-color: transparent;
  color: #1b3126;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  width: fit-content;
  border: none;
  outline: none;
}

.new-container > .news-card > .card > img {
  width: 100%;
  height: 193px;
  object-fit: cover;
}

.new-container > .news-card > .card > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 133.523%;
}

.new-container > .news-card > .card > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 133.523%;
}

.new-container > .news-card > .card > span {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.new-container > button {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  height: 44px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  border: 1px solid #000;
  background: #fff;
  margin-bottom: 100px;
}

@media screen and (max-width: 896px) {
  .new-container > .heading {
    padding: 16px;
    margin-top: 100px;
    margin-bottom: 0px;
  }

  .new-container > .heading > h2 {
    color: #202020;
    font-family: Campton;
    font-size: 42px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .new-container > .latest {
    width: 100%;
    flex-direction: column;
    padding: 16px;
  }

  .new-container > .latest > img {
    height: fit-content;
    width: 100%;
  }

  .new-container > .news-card {
    width: 100%;
    padding: 16px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 50px;
  }

  .new-container > .latest > .box {
    width: 100%;
  }

  .new-container > .latest > .box > h3 {
    color: #000;
    font-family: Campton;
    font-size: 22px;
    font-weight: 500;
  }

  .new-container > .latest > .box > p {
    color: #000;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 400;
  }
}
