* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Campton";
  src: url("../../../public/Campton-Light.ttf");
}
@font-face {
  font-family: "Colgent";
  src: url("../../../public/Colgent-Italic.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.invest-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.invest-container > .header {
  padding: 100px 80px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.invest-container > .header > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 93px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-bottom: 1px solid #202020;
}

.invest-container > .header > .video-card {
  width: 100%;
  min-height: 615.016px;
  position: relative;
}



.video-player video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.invest-container > .header > .video-card > .overley {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99%;
  background: rgba(18, 18, 18, 0.3);
  padding: 45px 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.invest-container > .header > .video-card > .overley > h2 {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.4%;
  width: 216px;
}

.invest-container > .header > .video-card > .overley > .box {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.invest-container > .header > .video-card > .overley > .box > button {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 130.4%;
  background-color: transparent;
  border-bottom: 1px solid #fff;
  outline: none;
  border: none;
  cursor: pointer;
}

.invest-container > .header > .video-card > .overley > .box > p {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 130.4%;
  letter-spacing: 0.84px;
}


.invest-container > .header > .wrapper-box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.invest-container > .header > .wrapper-box .box {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.invest-container > .header > .wrapper-box .box > p {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.4%;
}



.invest-container > .header > .wrapper-box .card-2 {
  width: 30%;
  height: 324px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://i.ibb.co/LdHs6XY/DJI-0410-2-1.png");
  position: relative;
  border-radius: 10px;
  border: 1px solid lightgray;
}


.invest-container > .header > .wrapper-box .card-2 > .impact-overlay   {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  position: absolute;
  background: rgba(18, 18, 18, 0.0);
}


.invest-container > .header > .wrapper-box .card-2 > .impact-overlay > button {
  width: 237px;
  height: 48px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  border-radius: 48px;
  border: none;
  outline: none;
  background: #fecb00;
  cursor: pointer;
}

@media screen and (max-width: 896px) {
  .invest-container > .header {
    padding: 100px 16px;
  }

  .invest-container > .header > h3 {
    color: #202020;
    font-family: Campton;
    font-size: 35px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .invest-container > .header > .video-card > .overley > .box > p {
    color: rgba(32, 32, 32, 0.9);
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 130.4%;
  }

  .invest-container > .header > .video-card > .overley {
    padding: 16px;
  }

  .invest-container > .header > .video-card > .overley > .box > button {
    color: #fff;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 130.4%;
  }
  .invest-container > .header > .video-card > .overley > .box > p {
    color: #fff;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 130.4%;
    letter-spacing: 0.66px;
  }
  .invest-container > .header > .video-card > .overley > h2 {
    color: #fff;
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 120.4%;
  }

  .invest-container > .header > .video-card{
    min-height: fit-content;
  }

  .invest-container > .header > .wrapper-box {
    flex-direction: column;
    align-items: center;
  }

  .invest-container > .header > .wrapper-box .card-2  {
    width: 70%;
  }
}

