* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Campton;
  src: url("../../../public/Campton-Bold.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.impact {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 20px 0px;
}

.impact > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 80px;
}

.impact > span > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 93px;
  font-weight: 600;
  margin-top: 104px;
}

.impact > .impact-line {
  width: 90%;
  height: 1px;
  background: #202020;
  margin: 15px auto 0px auto;
}

.impact > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 50px;
  padding: 0px 80px;
  margin-top: 30px;
}

.impact > .wrapper > .card-1 {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}

.impact > .wrapper > .card-1 > p {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 130.4%;
}

.impact > .wrapper > .card-1 > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.impact > .wrapper > .card-1 > span > p {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 400;
  line-height: 130.4%;
}

.impact > .download-con {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 100px;
  margin-top: 50px;
}

.impact > .download-con > .card-2 {
  width: 277px;
  height: 324px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://i.ibb.co/LdHs6XY/DJI-0410-2-1.png");
  position: relative;
  border-radius: 10px;
  border: 1px solid lightgray;
}
.impact > .download-con > .card-3 {
  width: 277px;
  height: 324px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://i.ibb.co/YtgWvYr/DJI-0410-1-2.png");
  position: relative;
  border-radius: 10px;
  border: 1px solid lightgray;
}
.impact > .download-con > .card-4 {
  width: 277px;
  height: 324px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://i.ibb.co/CBHfrR6/DJI-0410-1-3.png");
  position: relative;
  border-radius: 10px;
  border: 1px solid lightgray;
}

.impact > .download-con > .card-2  > .impact-overlay,
.impact > .download-con > .card-3  > .impact-overlay,
.impact > .download-con > .card-4  > .impact-overlay   {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  position: absolute;
  background: rgba(18, 18, 18, 0.0);
}


.impact > .download-con > .card-2  > .impact-overlay > button,
.impact > .download-con > .card-3  > .impact-overlay > button,
.impact > .download-con > .card-4  > .impact-overlay > button {
  width: 237px;
  height: 48px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 700;
  border-radius: 48px;
  border: none;
  outline: none;
  background: #fecb00;
  cursor: pointer;
}

.impact > .wrapper > .card-3 {
  display: none;
}

@media screen and (max-width: 896px) {
  .impact {
    padding: 50px 16px;
  }

  .impact > span {
    padding: 0px;
  }

  .impact > span > h3 {
    margin-top: 33px;
  }

  .impact > .wrapper {
    padding: 0px;
    margin-top: 30px;
    gap: 30px;
    flex-direction: column;
  }

  .impact > .wrapper > .card-1 {
    width: 100%;
  }
  .impact > .wrapper > .card-2 {
    width: 100%;
  }
  .impact > .wrapper > .card-3 {
    width: 100%;
    display: block;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .impact > .wrapper > .card-3 > p {
    color: rgba(32, 32, 32, 0.9);
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 400;
    line-height: 130.4%;
  }

  .impact > .wrapper > .card-1 > span {
    display: none;
  }
  .impact > .impact-line {
    width: 100%;
  }

  .impact > .download-con {
    flex-direction: column;
    gap: 20px;
  }
}

.impact > .iseya {
  width: 100%;
  height: 837px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("https://i.ibb.co/sVzR51M/DSC00123-1.png");
  margin-top: 110px;
}

.infrastructure-welfare {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 91px;
  gap: 50px;
  padding: 0px 80px;
}

.infrastructure-welfare > .card-1 {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
}

.infrastructure-welfare > .card-1 > .image-1 {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.infrastructure-welfare > .card-1 > .image-1 > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 130.4%;
}

.infrastructure-welfare > .card-1 > .image-1 > img {
  width: 100%;
  height: 550px;
  object-fit: cover;
}
.infrastructure-welfare > .card-1 > .image-2 {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  margin-top: 119px;
  cursor: pointer;
}

.infrastructure-welfare > .card-1 > .image-2 > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 130.4%;
}

.infrastructure-welfare > .card-1 > .image-2 > img {
  width: 100%;
  height: 550px;
  object-fit: cover;
}

.infrastructure-welfare > .card-2 {
  cursor: pointer;
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 5.5px solid #000;
  background: #fff;
  margin-top: 315px;
}

@media screen and (max-width: 896px) {
  .impact > .iseya {
    width: 100%;
    height: 788px;
    margin-top: 29px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .infrastructure-welfare {
    padding: 0px;
    flex-direction: column;
    margin-top: 70px;
  }

  .infrastructure-welfare > .card-1 {
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }

  .infrastructure-welfare > .card-1 > .image-1 {
    width: 100%;
  }
  .infrastructure-welfare > .card-1 > .image-2 {
    width: 100%;
    margin-top: 0%;
  }

  .infrastructure-welfare > .card-2 {
    display: none;
  }
}

.youth-empowerment {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 91px;
  gap: 50px;
  padding: 0px 80px;
}

.youth-empowerment > .card-1 {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;
  cursor: pointer;
}

.youth-empowerment > .card-1 > .image-1 {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
  margin-top: 119px;
}

.youth-empowerment > .card-1 > .image-1 > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 130.4%;
}

.youth-empowerment > .card-1 > .image-1 > img {
  width: 100%;
  height: 550px;
  object-fit: cover;
}
.youth-empowerment > .card-1 > .image-2 {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 5px;
}

.youth-empowerment > .card-1 > .image-2 > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 130.4%;
}

.youth-empowerment > .card-1 > .image-2 > img {
  width: 100%;
  height: 550px;
  object-fit: cover;
}

.youth-empowerment > .card-2 {
  width: 400px;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 5.5px solid #000;
  background: #fff;
  margin-top: 315px;
}

@media screen and (max-width: 896px) {
  .youth-empowerment {
    padding: 0px;
    flex-direction: column;
    margin-top: 20px;
  }

  .youth-empowerment > .card-1 {
    flex-direction: column;
    width: 100%;
    gap: 20px;
  }

  .youth-empowerment > .card-1 > .image-1 {
    width: 100%;
    margin-top: 0%;
  }
  .youth-empowerment > .card-1 > .image-2 {
    width: 100%;
    margin-top: 0%;
  }

  .youth-empowerment > .card-2 {
    display: none;
  }

  .youth-empowerment > .card-1 > .image-1 > h3 {
    font-size: 16px;
  }

  .youth-empowerment > .card-1 > .image-2 > h3{
    font-size: 14px;
  }
}

.impact > .agriculture {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  gap: 5px;
  padding: 0px 80px;
  margin-top: 91px;
}

.impact > .agriculture > h3 {
  color: #000;
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 600;
  line-height: 130.4%;
}

.impact > .agriculture > img {
  width: 33%;
  height: 560px;
  object-fit: cover;
}

@media screen and (max-width: 896px) {
  .impact > .agriculture {
    padding: 0px;
    margin-top: 20px;
  }

  .impact > .agriculture > img {
    width: 100%;
  }
}
