* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Campton;
  src: url("../../../public/Campton-Bold.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.development {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: 100px 0px;
}

.development > .image {
  width: 100%;
  height: 653px;
  padding: 20px 80px;
}

.development > .image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.infrastructure-develop {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 40px;
  gap: 45px;
  padding: 0px 80px 0px 297px;
}

.infrastructure-develop > .card-1 {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}

.infrastructure-develop > .card-1 > .dev-line {
  width: 630px;
  height: 5px;
  background: #121212;
}

.infrastructure-develop > .card-1 > h3 {
  color: #000;
  font-family: Campton;
  font-size: 30px;
  font-weight: 500;
  line-height: 130.4%;
  margin-top: 20px;
}
.infrastructure-develop > .card-1 > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
}

.infrastructure-develop > .card-1 > span {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 30px;
}

.infrastructure-develop > .card-1 > span > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.2%;
}

.infrastructure-develop > .card-2 {
  display: flex;
  width: 290px;
  height: 290px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 5.5px solid #000;
  background: #fff;
  margin-top: 35%;
}

@media screen and (max-width: 896px) {
  .infrastructure-develop {
    padding: 10px;
  }

  .development > .image {
    padding: 0px;
  }

  .infrastructure-develop > .card-1 {
    width: 100%;
  }

  .infrastructure-develop > .card-1 > h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .infrastructure-develop > .card-2 {
    display: none;
  }
  .infrastructure-develop > .card-1 > .dev-line {
    width: 100%;
  }
}

.development > .wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 22px;
}

.development > .wrapper > .card-1 {
  width: 740px;
  height: 455px;
}

.development > .wrapper > .card-1 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.development > .wrapper > .card-2 {
  width: 520px;
  height: 370px;
}

.development > .wrapper > .card-2 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 896px) {
  .development > .wrapper {
    width: 100%;
    flex-direction: column;
    padding: 10px;
  }

  .development > .wrapper > .card-1 {
    width: 100%;
  }
  .development > .wrapper > .card-2 {
    width: 100%;
  }
}

.development > .cargo-plane {
  width: 100%;
  height: 859px;
  margin-top: 20px;
}

.development > .cargo-plane > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.development > .city-gate {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 22px;
  margin-top: 20px;
  padding: 0px 80px 20px 80px;
}

.development > .city-gate > .card-1 {
  width: 50%;
  height: 472px;
}

.development > .city-gate > .card-1 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.development > .city-gate > .card-2 {
  width: 50%;
  height: 472px;
}

.development > .city-gate > .card-2 > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media screen and (max-width: 896px) {
    .development {
        padding: 80px 0px 0px 0px;
    }
  .development > .cargo-plane {
    padding: 10px;
    height: 343px;
    border-radius: 5px;
  }

  .development > .city-gate {
    padding: 10px;
    flex-direction: column;
  }

  .development > .city-gate > .card-1 {
    width: 100%;
  }
  .development > .city-gate > .card-2 {
    width: 100%;
  }

  .development > .image > img{
    min-height: 200px;
  }
}
