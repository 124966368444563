.custom-loader {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    padding: 3px;
    background:
      conic-gradient(from 135deg at top,#FFFFFF 90deg, #0000 0) 0 calc(50% - 2px)/8.5px 4.25px,
      radial-gradient(farthest-side at bottom left,#0000 calc(100% - 3px),#FFFFFF calc(100% - 2.5px) 99%,#0000) top right/50%  50% content-box content-box,
      radial-gradient(farthest-side at top        ,#0000 calc(100% - 3px),#FFFFFF calc(100% - 2.5px) 99%,#0000) bottom   /100% 50% content-box content-box;
    background-repeat: no-repeat;
    animation:s1 0.5s infinite linear;
  }
  
  @keyframes s1{ 
    100%{transform: rotate(1turn)}
  }