* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../public/Montserrat-VariableFont_wght.ttf");
}

.navbar-container {
  width: 100%;
  height: 80px;
  padding: 0px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  position: fixed;
  top: 0%;
  z-index: 100;
}

.navbar-container.not-home {
  border-bottom: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
}

.navbar-container.not-home > .nav-links > a {
  color: #020202;
}

.navbar-container > .mobile-menu {
  display: none;
}

.navbar-container > .nav-links {
  display: flex;
  align-items: center;
  gap: 40px;
}

.navbar-container > .nav-links > a {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration: none;
}

.navbar-container > .nav-links > .contact {
  display: none;
}

.navbar-container > .contact {
  height: 44px;
  padding: 10px 20px;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 48px;
  background: var(--yellow, #fecb00);
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 896px) {
  .navbar-container {
    padding: 0px 16px;
  }
  .navbar-container > .contact {
    display: none;
  }

  .navbar-container > .mobile-menu {
    display: block;
  }

  .navbar-container > .nav-links > a {
    color: rgba(0, 0, 0, 0.8);
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
  }

  .navbar-container > .nav-links {
    width: 100%;
    height: 100vh;
    position: absolute;
    right: 0%;
    transform: translateX(-100%);
    transition: all 0.3s ease;
    top: 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background: #fff;
    padding: 16px;
  }

  .mobile-link.active {
    transform: translateX(0);
  }

  .navbar-container > .nav-links > .contact {
    display: block;
  }
}
