* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Campton";
  src: url("../../../public/Campton-Light.ttf");
}
@font-face {
  font-family: "Colgent";
  src: url("../../../public/Colgent-Italic.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.year-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.year-details > .header {
  width: 100%;
  padding: 30px 80px;
  margin: 80px 0px 20px 0px;
  display: flex;
  flex-direction: column;
  gap: 22px;
}

.year-details > .header > a {
  display: flex;
  gap: 10px;
  align-items: center;
  color: rgba(32, 32, 32, 0.5);
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
}

.year-details > .header > a > .icon {
  width: 24px;
  height: 24px;
}

.year-details > .header > a > p {
  color: #1b3126;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500;
}

.year-details > .header > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.year-details > .loading-card {
  display: flex;
  align-items: center;
  gap: 30px;
  margin: 20px;
  flex-direction: row;
}

.year-details > .festivals {
  padding: 20px 80px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 20px;
}

.year-details > .festivals > .card {
  width: 100%;
  position: relative;
}

.year-details > .festivals > .card > img {
  width: 100%;
  height: 320px;
  object-fit: cover;
}

.year-details > .festivals > .card > .wrap {
  position: absolute;
  bottom: 0%;
  padding: 22px;
}

.year-details > .festivals > .card > .wrap > h3 {
  color: #fff;
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 500;
}

@media screen and (max-width: 896px) {
  .year-details > .header {
    padding: 30px;
  }

  .year-details > .header > h3 {
    font-size: 26px;
  }

  .year-details > .festivals {
    padding: 26px;
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }

  .year-details > .festivals > .card > img {
    border-radius: 10px;
  }

  .year-details > .loading-card {
    flex-direction: column;
    width: 97%;
    margin: auto;
  }
}
