* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Campton";
  src: url("../../public/Campton-Light.ttf");
}
@font-face {
  font-family: "Colgent";
  src: url("../../public/Colgent-Italic.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../public/Montserrat-VariableFont_wght.ttf");
}

.footer-container {
  width: 100%;
  min-height: 405px;
  background: #1b3126;
  padding: 124px 80px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-container > .box1 {
  width: 421px;
  display: flex;
  flex-direction: column;
}

.footer-container > .box1 > svg {
  width: 152.678px;
  height: 70.21px;
}

.footer-container > .box1 > p {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125.295%;
  margin: 40px 0px 24px 0px;
}

.footer-container > .box1 > h3 {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.footer-container > .box2 {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.footer-container > .box2 > a {
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
}

.footer-container > .box3 {
  display: flex;
  flex-direction: column;
  width: 411px;
  justify-content: flex-end;
  align-items: center;
  gap: 50px;
}

.footer-container > .box3 > form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.footer-container > .box3 > form > label {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #fff;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
}

.footer-container > .box3 > form > label > span {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #fff;
  background: #1b3126;
}

.footer-container > .box3 > form > label > span > input {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  padding-left: 15px;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.footer-container > .box3 > form > label > span > input::placeholder {
  color: white;
}

.footer-container > .box3 > form > label > span > button {
  background-color: transparent;
  cursor: pointer;
  outline: none;
  border: none;
}

.footer-container > .box3 > form > label > span > button > .icon {
  width: 24px;
  height: 24px;
  color: white;
}

.footer-container > .box3 > .social {
  width: 100%;
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.footer-container > .box3 > .social > p {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.footer-container > .box3 > .social > .icons {
  width: 100%;
  display: flex;
  gap: 30px;
}

.footer-container > .box3 > .social > .icons > a {
  text-decoration: none;
}

.footer-container > .right {
  display: none;
}

.footer-container > form{
  display: none;
}

@media screen and (max-width: 896px) {
  .footer-container {
    height: fit-content;
    flex-direction: column;
    align-items: start;
    padding: 50px 16px;
  }

  .footer-container > .box2 {
    gap: 17px;
  }

  .footer-container > .box3,
  .footer-container > .box1 {
    width: 100%;
  }

  .footer-container > .right {
    display: block;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }

  .footer-container > .right > h3 {
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .footer-container > .box1 > h3 {
    display: none;
  }

  .footer-container > .box1 >  svg {
    width: 110px;
    height: 50px;
  }

  .footer-container > .box3 > form {
    display: none;
  }

  .footer-container > .box3 > .social {
    margin-top: 30px;
  }



  .footer-container > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: 25px 0px;
    display: block;
  }
  
  .footer-container  > form > label {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: #fff;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 600;
  }
  
  .footer-container >  form > label > span {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding: 10px;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #fff;
    background: #1b3126;
  }
  
  .footer-container >  form > label > span > input {
    width: 100%;
    border: none;
    outline: none;
    background-color: transparent;
    padding-left: 15px;
    color: #fff;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
  }
  
  .footer-container >  form > label > span > input::placeholder {
    color: white;
  }
  
  .footer-container >  form > label > span > button {
    background-color: transparent;
    cursor: pointer;
    outline: none;
    border: none;
  }

  .footer-container >  form > label > span > button > .icon {
    width: 24px;
    height: 24px;
    color: white;
  }
}
