* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Campton";
  src: url("../../../public/Campton-Bold.ttf");
}
@font-face {
  font-family: "Colgent";
  src: url("../../../public/Colgent-Italic.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.media-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
}

.media-container > .header {
  width: 100%;
  padding: 0px 80px;
}

.media-container > .header > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 93px;
  font-weight: 600;
  border-bottom: 1px solid #202020;
}

.media-container > .scrolling-media {
  width: 100%;
  padding-left: 80px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  gap: 20px;
}

.media-container > .scrolling-media > p {
  color: #000;
  font-family: Campton;
  font-size: 18px;
  font-weight: 400;
}

.media-container > .explore-year {
  width: 100%;
  padding: 0px 80px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-bottom: 100px;
}

.media-container > .explore-year > h3 {
  color: #000;
  font-family: Campton;
  font-size: 18px;
  font-weight: 400;
  border-top: 1px solid #202020;
  padding-top: 30px;
}

.media-container > .explore-year > .year-card {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 4fr);
  gap: 20px;
}

.media-container > .explore-year > .year-card > a {
  width: 100%;
  height: 305px;
  text-decoration: none;
  position: relative;
}

.media-container > .explore-year > .year-card > a > img {
  width: 100%;
  height: 100%;
}

.media-container > .explore-year > .year-card > a > .overley {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
  background-color: rgba(5, 5, 5, 0.2);
  padding: 20px;
  display: flex;
  align-items: flex-end;
}

.media-container > .explore-year > .year-card > a > .overley > p {
  color: #fff;
  font-family: Campton;
  font-size: 20px;
  font-weight: 500;
}

@media screen and (max-width: 896px) {
  .media-container > .header {
    width: 100%;
    padding: 16px;
  }
  .media-container > .header > h3 {
    color: #202020;
    font-family: Campton;
    font-size: 42px;
    font-weight: 500;
  }

  .media-container > .scrolling-media  {
    width: 100%;
    padding: 16px;
    height: fit-content;
  }

  .media-container > .explore-year {
    margin-bottom: 20px;
    padding: 16px;

  }

  .media-container > .explore-year > .year-card {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    gap: 20px;
  }

  .media-container > .explore-year > .year-card > a {
    height: 100%;
  }

  .media-container > .explore-year > .year-card > a > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .media-container > .explore-year > .year-card > a > .overley {
    height: 100%;
  }
}
