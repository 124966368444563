* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Campton;
  src: url(/public/Campton-Light.ttf);
}

@font-face {
  font-family: Montserrat;
  src: url(/public/Campton-Light.ttf);
}

.page-not-found {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  margin-top: 131px;
}
.page-not-found > h3 {
  color: #202020;
  font-family: Montserrat;
  font-size: 46px;
  font-weight: 600;
}
.page-not-found > p {
  color: rgba(32, 32, 32, 0.9);
  text-align: center;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 500;
  line-height: 132.1%;
  width: 40%;
}

.page-not-found > a {
  height: 44px;
  display: flex;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 80px;
  background: #254a38;
  border: none;
  outline: none;
  text-decoration: none;
  font-family: Montserrat;
  color: white;
  margin-bottom: 250px;
}


@media screen and (max-width:896px) {
  .page-not-found > p{
    width: 90%;
  }
}