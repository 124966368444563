.scroll-to-top {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: rgba(9, 9, 9,0.8);
    color: #fff;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .scroll-to-top:hover {
    background-color: rgba(9, 9, 9,0.6);
  }