* {
  padding: 0%;
  margin: 0%;
  box-sizing: border-box;
}

@font-face {
  font-family: Campton;
  src: url("../../../public/Campton-Bold.ttf");
}

@font-face {
  font-family: Montserrat;
  src: url("../../../public//Montserrat-VariableFont_wght.ttf");
}

.news-detail {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 100px;
  padding: 120px 100px;
}

.news-detail > .card-1 {
  width: 70%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
}

.news-detail > .card-1 > h3 {
  color: #000;
  font-family: Campton;
  font-size: 38px;
  font-weight: 600;
}
.news-detail > .card-1 > h4 {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}
.news-detail > .card-1 > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
  line-height: 153.6%;
}

.news-detail > .card-1 > img {
  width: 100%;
  height: 406px;
  object-fit: cover;
}

.news-detail > .card-2 {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 20px;
  padding: 50px 0px;
}

.news-detail > .card-2 > h3 {
  color: #1b3126;
  font-family: Campton;
  font-size: 20px;
  font-weight: 700;
  line-height: 133.523%;
}

.news-detail > .card-2 > .wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 30px;
}

.news-detail > .card-2 > .wrapper > .card {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  text-decoration: none;
}

.news-detail > .card-2 > .wrapper > .card > span {
  width: 65%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: 10px;
}

.news-detail > .card-2 > .wrapper > .card > span > p {
  color: rgba(32, 32, 32, 0.8);
  font-family: Montserrat;
  font-size: 13px;
  font-weight: 400;
}
.news-detail > .card-2 > .wrapper > .card > span > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 18px;
  font-weight: 500;
  line-height: 133.523%;
}

.news-detail > .card-2 > .wrapper > .card > img {
  width: 35%;
  height: 100px;
  object-fit: cover;
}

@media screen and (max-width: 896px) {
  .news-detail {
    padding: 100px 16px;
    flex-direction: column;
    gap: 30px;
  }

  .news-detail > .card-1 {
    width: 100%;
  }

  .news-detail > .card-1 > h3 {
    font-size: 22px;
  }
  .news-detail > .card-2 {
    width: 100%;
  }
}
