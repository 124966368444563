.Home-slider-middle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
}

.Home-slider-middle > .btn > svg {
  cursor: pointer;
}

.Home-slider-middle > .scrolling {
  width: 100%;
  height: 500px;
  display: flex;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-right: 10px;
  scroll-behavior: smooth;
  transition: scroll-left 0.2s ease;
}

.Home-slider-middle > .scrolling {
  scrollbar-width: none;
}

.Home-slider-middle > .scrolling::-webkit-scrollbar {
  display: none;
}

.Home-slider-middle > .scrolling > .box {
  width: 500px;
  height: 500px;
  flex-shrink: 0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}

.Home-slider-middle > .scrolling > .box > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Home-slider-middle > .scrolling > .box:nth-child(1) {
  background: #cea608;
}

.Home-slider-middle > .scrolling > .box:nth-child(1) > h3,
.Home-slider-middle > .scrolling > .box:nth-child(5) > h3 {
  color: #fff;
  font-family: Campton;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.Home-slider-middle > .scrolling > .box > .below {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.Home-slider-middle > .scrolling > .box > img {
  width: 90%;
  position: absolute;
  top: 0%;
  left: 0;
  transition: 0.3s ease-in-out;
  opacity: 0;
  object-fit: contain;
}

.Home-slider-middle > .scrolling > .box:hover > img {
  transform: rotate(-45deg) scale(1.1);
  opacity: 1;
}

.Home-slider-middle > .scrolling > .box > .below > h3 {
  color: #fff;
  font-family: Campton;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.3s ease-in-out;
}

.Home-slider-middle > .scrolling > .box:hover > .below > h3 {
  margin-bottom: 90px;
}

.Home-slider-middle > .scrolling > .box > p {
  color: #fff;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  position: absolute;
  bottom: -60px;
  transition: 0.3s ease-in-out;
  width: 350px;
}

.Home-slider-middle > .scrolling > .box:hover > p {
  bottom: 60px;
}

.Home-slider-middle > .scrolling > .box:nth-child(2):hover > p,
.Home-slider-middle > .scrolling > .box:nth-child(4):hover > p {
  bottom: 75px;
}

.Home-slider-middle > .scrolling > .box:nth-child(2) {
  background: #f9e181;
}
.Home-slider-middle > .scrolling > .box:nth-child(3) {
  background: #86e2f6;
}
.Home-slider-middle > .scrolling > .box:nth-child(4) {
  background: #69e3a3;
}
.Home-slider-middle > .scrolling > .box:nth-child(5) {
  background: #419a2b;
}

@media screen and (max-width: 768px) {
  .Home-slider-middle > .scrolling > .box {
    width: 97%;
    padding: 30px;
  }
  .Home-slider-middle > .scrolling > .box > img {
    width: 100%;
  }
  .Home-slider-middle > .scrolling > .box > .below > h3 {
    color: #fff;
    font-family: Campton;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .Home-slider-middle > .scrolling > .box > p {
    width: 80%;
  }

  .Home-slider-middle > .scrolling > .box:nth-child(2):hover > p,
  .Home-slider-middle > .scrolling > .box:nth-child(4):hover > p {
    bottom: 60px;
  }
  .Home-slider-middle > .scrolling > .box:nth-child(5) > p {
    bottom: -75px;
  }
  .Home-slider-middle > .scrolling > .box:nth-child(3) > p {
    bottom: -75px;
  }
  .Home-slider-middle > .scrolling > .box:nth-child(5):hover > p,
  .Home-slider-middle > .scrolling > .box:nth-child(3):hover > p {
    bottom: 40px;
  }

  .Home-slider-middle > .scrolling {
    width: calc(100% + 100px);
  }
}
