  * {
    padding: 0%;
    margin: 0%;
    box-sizing: border-box;
  }

  @font-face {
    font-family: Campton;
    src: url("../../../public/Campton-Bold.ttf");
  }

  @font-face {
    font-family: Montserrat;
    src: url("../../../public//Montserrat-VariableFont_wght.ttf");
  }

  .tribunal {
    width: 100%;
    display: flex;
    min-height: 200px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 150px 80px;
    gap: 108px;
  }

  .tribunal > .speech-1 {
    width: 25%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .tribunal > .speech-1 > h3 {
    color: #000;
    font-family: Campton;
    font-size: 30px;
    font-weight: 500;
    width: 325px;
  }
  .tribunal > .speech-1 > p {
    color: #202020;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    line-height: 132.1%;
  }

  .tribunal > .speech-2 {
    width: 75%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
  }

  .tribunal > .speech-2 > .wrapper {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 50px;
  }

  .tribunal > .speech-2 > .wrapper > .card-1 {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  .tribunal > .speech-2 > .wrapper > .card-1 > div {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.28px;
  }

  .tribunal > .speech-2 > img {
    object-fit: cover;
    width: 100%;
    height: 400px;
  }

  .tribunal > .speech-2 > .wrapper > .card-2 {
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column; 
  }

  .tribunal > .speech-2 > .wrapper > .card-2 > div {
    color: #202020;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.28px;
  }

  @media screen and (max-width: 896px) {
    .tribunal {
      padding: 100px 16px;
      flex-direction: column;
      gap: 40px;
    }

    .tribunal > .speech-1 {
      width: 100%;
    }
    .tribunal > .speech-2 {
      width: 100%;
    }

    .tribunal > .speech-2 > .wrapper {
      flex-direction: column;
      gap: 10px;
    }

    .tribunal > .speech-2 > .wrapper > .card-1 {
      width: 100%;
    }

    .tribunal > .speech-2 > .wrapper > .card-2 {
      width: 100%;
    }

    .tribunal > .speech-1 > h3 {
      color: #202020;
      font-family: Campton;
      font-size: 22px;
      font-weight: 500;
      letter-spacing: 0.44px;
    }

    .tribunal > .speech-2 > img  {
      height: auto;
    }
  }
