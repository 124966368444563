* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Campton";
  src: url("../../../public/Campton-Light.ttf");
}
@font-face {
  font-family: "Colgent";
  src: url("../../../public/Colgent-Italic.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.speech-container {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.speech-container > .hero {
  width: 100%;
  height: 530px;
  position: relative;
  background-image: url("https://i.ibb.co/Lg8VJ8L/DSC04123-1.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.speech-container > .hero > .overley {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  padding: 80px;
  display: flex;
  align-items: flex-end;
}

.speech-container > .hero > .overley > p {
  color: #fff;
  font-family: Campton;
  font-size: 74px;
  font-style: normal;
  font-weight: 800;
  line-height: 71.1px;
  letter-spacing: -0.296px;
}

@media screen and (max-width: 896px) {
  .speech-container > .hero {
    height: 603px;
    background-image: url("https://i.ibb.co/phD5MTG/DSC00123-2-3.png");
  }

  .speech-container > .hero > .overley {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .speech-container > .hero > .overley > p {
    color: #fff;
    font-family: Campton;
    font-size: 54px;
    font-style: normal;
    font-weight: 400;
    line-height: 71.1px;
    letter-spacing: -0.216px;
  }
}

.speech-container > .speech-card {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 80px;
}

.speech-container > .speech-card > .switch {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.speech-container > .speech-card > .switch > .inner {
  display: flex;
  gap: 15px;
}

.speech-container > .speech-card > .switch > .inner > button:nth-child(1) {
  display: flex;
  width: 90px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: var(--yellow, #fecb00);
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  cursor: pointer;
}

.speech-container > .speech-card > .switch > .inner > button {
  display: flex;
  width: 90px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  border-radius: 40px;
}

.speech-container > .speech-card > .switch > .inner > button:hover {
  background: var(--yellow, #fecb00);
  transition: 0.5s ease-in-out;
}

.speech-container > .speech-card > .switch > .search {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.speech-container > .speech-card > .switch > .search > span {
  display: flex;
  width: 307px;
  height: 44px;
  padding: 10px 15px;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: #fff;
  display: flex;
  justify-content: space-between;
}

.speech-container > .speech-card > .switch > .search > span > input {
  width: 100%;
  border: none;
  outline: none;
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-left: 10px;
  background-color: transparent;
}

.speech-container > .speech-card > .wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 3fr);
  gap: 30px;
  margin: 60px 0px;
}

.speech-container > .speech-card > .wrapper > .card {
  display: flex;
  width: 100%;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 36px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: #fff;
}

.speech-container > .speech-card > .wrapper > .card > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.48px;
}

.speech-container > .speech-card > .wrapper > .card > p {
  color: rgba(32, 32, 32, 0.5);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 132.1%;
}

.speech-container > .speech-card > .wrapper > .card > a {
  color: var(--green, #2b9a5f);
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130.4%;
  background-color: transparent;
  border: none;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.speech-container > .speech-card > .wrapper > .card > a > .icon {
  color: #2b9a5f;
  width: 24px;
  height: 24px;
}

.speech-container > .speech-card > button {
  display: inline-flex;
  height: 44px;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  border: 1px solid #000;
  background: #fff;
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: pointer;
}

@media screen and (max-width: 896px) {
  .speech-container > .speech-card {
    padding: 80px 16px;
  }
  .speech-container > .speech-card > .switch {
    flex-direction: column-reverse;
    gap: 12px;
  }

  .speech-container > .speech-card > .switch > .inner {
    width: 100%;
    overflow: scroll;
  }

  .speech-container > .speech-card > .switch > .inner::-webkit-scrollbar {
    display: none;
  }

  .speech-container > .speech-card > .wrapper {
    grid-template-columns: repeat(1, 1fr);
    gap: 16px;
  }

  .speech-container > .speech-card > .wrapper > .card > h3 {
    color: #202020;
    font-family: Campton;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.28px;
  }

  .speech-container > .speech-card > .wrapper > .card > p {
    color: rgba(32, 32, 32, 0.5);
    font-family: Montserrat;
    font-size: 11px;
    font-style: normal;
    font-weight: 500;
    line-height: 132.1%;
  }

  .speech-container > .speech-card > .wrapper > .card > a {
    color: var(--green, #2b9a5f);
    font-family: Montserrat;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 130.4%;
  }

  .speech-container > .speech-card > .wrapper > .card > a > .icon {
    width: 14px;
    height: 14px;
  }

  .speech-container > .speech-card > .wrapper > .card {
    display: flex;
    padding: 12px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    border: 1px solid rgba(0, 0, 0, 0.3);
    background: #fff;
  }
  .speech-container > .speech-card > .switch > .search {
    width: 100%;
    justify-content: space-between;
  }

  .speech-container > .speech-card > .switch > .search > span {
    height: 44px;
    width: 100%;
  }
}


.year-error {
  color: rgba(32, 32, 32, 0.5);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 132.1%;
  margin: 20px;
}
