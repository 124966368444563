* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Campton";
  src: url("../../../public/Campton-Bold.ttf");
}
@font-face {
  font-family: "Colgent";
  src: url("../../../public/Colgent-Italic.ttf");
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.contact-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 50px;
  overflow: hidden;
}

.contact-container > .header {
  width: 100%;
  padding: 40px 80px;
  display: flex;
  justify-content: space-between;
}

.contact-container > .header > .in {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact-container > .header > .in > h1 {
  color: #202020;
  font-family: Campton;
  font-size: 93px;
  font-weight: 600;
  width: 372px;
  flex-shrink: 0;
}

.contact-container > .header > .in > p {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.contact-container > .header > .box {
  display: flex;
  flex-direction: column;
  gap: 20px;
  min-height: 500px;
  padding-top: 110px;
  width: 50%;
}

.contact-container > .header > .box > p {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

.contact-container > .header > .box > form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 20px;
}

.contact-container > .header > .box > form > input {
  width: 100%;
  height: 54px;
  display: flex;
  padding-left: 15px;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  outline: none;
  color: rgba(0, 0, 0, 0.8);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.contact-container > .header > .box > form > textarea {
  width: 100%;
  min-height: 166px;
  outline: none;
  border: 1px solid rgba(32, 32, 32, 0.5);
  background: #fff;
  outline: none;
  color: rgba(0, 0, 0, 0.8);
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
  padding: 15px;
}

.contact-container > .header > .box > form > button {
  display: flex;
  padding: 12px 20px;
  align-items: flex-end;
  gap: 10px;
  border-radius: 30px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background: #fff;
  color: #202020;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.45px;
  cursor: pointer;
  outline: none;
}

.contact-container > .header > .box > form > p {
  color: #2b9a5f;
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .contact-container {
    padding-top: 90px;
  }

  .contact-container > .header {
    padding: 20px 16px;
    flex-direction: column;
  }

  .contact-container > .header > .in {
    width: 100%;
    gap: 10px;
  }

  .contact-container > .header > .in > h1 {
    color: #202020;
    font-family: Campton;
    font-size: 42px;
    font-weight: 500;
  }

  .contact-container > .header > .in > p {
    color: #000;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
  }

  .contact-container > .header > .box  {
    width: 100%;
    padding: 0px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 896px) {
    .contact-container {
      padding-top: 90px;
    }
  
    .contact-container > .header {
      padding: 20px 16px;
      flex-direction: column;
    }
  
    .contact-container > .header > .in {
      width: 100%;
      gap: 10px;
    }
  
    .contact-container > .header > .in > h1 {
      color: #202020;
      font-family: Campton;
      font-size: 42px;
      font-weight: 500;
    }
  
    .contact-container > .header > .in > p {
      color: #000;
      font-family: Montserrat;
      font-size: 14px;
      font-weight: 500;
    }
  
    .contact-container > .header > .box  {
      width: 100%;
      padding: 0px;
      margin-top: 20px;
    }
  }
  