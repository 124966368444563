* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: "Campton";
  src: url("../../../public/Campton-Bold.ttf");
  font-style: normal;
}
@font-face {
  font-family: "Colgent";
  src: url("../../../public/Colgent-Italic.ttf");
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("../../../public/Montserrat-VariableFont_wght.ttf");
  font-style: normal;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px white inset;
}

.home-sub-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0%;
  background: rgba(18, 18, 18, 0.6);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 101;
  padding: 16px;
}

.home-sub-container > .card {
  width: 471px;
  height: 370px;
  display: flex;
  padding: 30px;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  background: #fffcf4;
  position: relative;
}

.home-sub-container > .card > svg {
  width: 163.551px;
  height: 75.21px;
  margin-bottom: 50px;
}

.home-sub-container > .card > .icon {
  position: absolute;
  right: 20px;
  top: 30px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.home-sub-container > .card > .in {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.home-sub-container > .card > .in > h1 {
  color: #121212;
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 600;
}

.home-sub-container > .card > .in > p {
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.home-sub-container > .card > form {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 20px 0px;
}

.home-sub-container > .card > form > label {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.home-sub-container > .card > form > label > span {
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  display: flex;
  padding: 10px;
  align-items: center;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #121212;
  background: #fffcf4;
}

.home-sub-container > .card > form > label > span .icon {
  width: 24px;
  height: 24px;
}

.home-sub-container > .card > form > label > span > input {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  padding-left: 15px;
  color: #202020;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 400;
}

.home-sub-container > .card > form > label > span > input::placeholder {
  color: white;
}

.home-sub-container > .card > form > button {
  display: flex;
  height: 50px;
  padding: 16.5px 0px 15.5px 0px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: #fecb00;
  color: #202020;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 800;
  font-style: normal;
  border: none;
  margin-top: 20px;
  cursor: pointer;
}

.home-sub-container > .card > form > button:disabled {
  background-color: lightgray;
  cursor: not-allowed;
}

.home-sub-container > .card > form > .succ {
  color: green;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 800;
  margin: 8px auto;
}
.home-sub-container > .card > form > .error {
  color: red;
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 800;
  margin: 10px auto;
}

.home-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.home-container > .hero-container {
  width: 100%;
  position: relative;
  height: 100vh;
}

.home-container > .hero-container > .overlay {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0);
  z-index: 1;
  padding: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  gap: 94px;
}

.hero-container > .overlay > .wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: rgba(0, 0, 0, 0);
}

.hero-container > .overlay > .wrapper > h3 {
  width: 653px;
  color: #fff;
  font-family: Campton;
  font-size: 74px;
  font-style: normal;
  font-weight: 600;
  line-height: 71.1px;
  letter-spacing: -0.296px;
}

.hero-container > .overlay > .wrapper > p {
  color: #fff;
  font-family: Colgent;
  font-size: 74px;
  font-style: italic;
  font-weight: 400;
  line-height: 71.1px;
  letter-spacing: -0.296px;
}

.hero-container > .overlay > .wrapper > p > span {
  color: #fff;
  font-family: Colgent;
  font-size: 34px;
  font-style: italic;
  font-weight: 400;
  line-height: 71.1px;
  letter-spacing: -0.136px;
}

.hero-container > .overlay > .scroll {
  width: 95%;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 10px;
}

.hero-container > .overlay > .scroll > p {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.28px;
}

.hero-container > .overlay > .scroll > .animate {
  width: 20px;
  height: 40px;
  background-color: transparent;
  border: 2px solid #fecb00;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  padding: 2px;
}

.hero-container > .overlay > .scroll > .animate > span {
  width: 3px;
  height: 17px;
  border-radius: 30px;
  background-color: #fecb00;
  animation: moveUpDown 0.4s infinite alternate;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(15px);
  }
}

.slider-container {
  width: 100%;
  height: 100vh;
  position: relative;
}

.slider-container .slick-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slider-containers {
  width: 100%;
  height: 100vh;
  display: none;
  position: relative;
}

@media screen and (max-width: 896px) {
  .home-sub-container > .card > .in > p {
    color: #121212;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 400;
  }
  .home-container > .hero-container {
    height: 100vh;
  }
  .home-container > .hero-container > .overlay {
    padding: 16px;
  }
  .home-container > .hero-container > .overlay > .wrapper {
    width: 100%;
    height: 100%;
    justify-content: center;
  }

  .home-container > .hero-container > .overlay > .wrapper > h3 {
    width: 254px;
    color: #fff;
    font-family: Campton;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: -0.12px;
    margin-top: 100px;
  }
  .slider-container {
    display: none;
  }

  .slider-containers {
    display: block;
    width: 100%;
    height: 100vh;
  }

  .slider-containers .slick-slide img {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .hero-container > .overlay > .wrapper > p {
    color: #fff;
    font-family: Colgent;
    font-size: 30px;
    font-style: italic;
  }

  .hero-container > .overlay > .wrapper > p > span {
    color: #fff;
    font-family: Colgent;
    font-size: 16px;
    font-style: italic;
  }

  .hero-container > .overlay > .scroll {
    width: 100%;
    margin-bottom: 120px;
  }
}

.home-container > .we-building-container {
  width: 100%;
  height: 383px;
  padding: 0px 527px 0px 184px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
}

.home-container > .we-building-container > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.76px;
}

.home-container > .we-building-container > p {
  color: #202020;
  font-family: Campton;
  font-size: 38px;
  font-style: italic;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.76px;
}

@media screen and (max-width: 896px) {
  .home-container > .we-building-container {
    height: 317px;
    padding: 16px;
    width: 100%;
  }

  .home-container > .we-building-container > h3 {
    font-size: 18px;
  }
  .home-container > .we-building-container > p {
    font-size: 18px;
  }
}

.home-container > .about-dapo {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.home-container > .about-dapo > .box1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 626px;
  padding: 0px 80px;
}

.home-container > .about-dapo > .box1 > .wrapp {
  display: flex;
  align-items: center;
}

.home-container > .about-dapo > .box1 > .wrapp > p {
  color: #2b9a5f;
  font-family: Campton;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.home-container > .about-dapo > .box1 > .wrapp > span {
  width: 30px;
  height: 2px;
  flex-shrink: 0;
  background: #2b9a5f;
}

.home-container > .about-dapo > .box1 > .inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 50px 0px;
  padding-left: 22px;
}

.home-container > .about-dapo > .box1 > .inner > p {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.4%;
}

.home-container > .about-dapo > .box1 > a {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 130.4%;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  text-decoration: none;
  margin-left: 22px;
}

.home-container > .about-dapo > .box2 {
  width: 100%;
  height: 626px;
  background-image: url("https://i.ibb.co/8cPq33q/DSC00123-1-2x.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media screen and (max-width: 896px) {
  .home-container > .about-dapo {
    flex-direction: column-reverse;
  }

  .home-container > .about-dapo > .box1 {
    padding: 16px;
  }

  .home-container > .about-dapo > .box1 > .wrapp > span {
    display: none;
  }

  .home-container > .about-dapo > .box1 > .inner {
    padding: 0px;
  }

  .home-container > .about-dapo > .box1 > a {
    margin: 0;
  }
}

.home-container > .to-give-ogun {
  width: 100%;
  height: 440px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #2b4437;
  overflow: hidden;
}

.home-container > .to-give-ogun > .box {
  display: flex;
  justify-content: center;
  width: 776px;
}

.home-container > .to-give-ogun > .box > svg {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
}

.home-container > .to-give-ogun > .box > p {
  color: #fff;
  text-align: center;
  font-family: Campton;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.6px;
}

@media screen and (max-width: 896px) {
  .home-container > .to-give-ogun {
    padding: 16px;
  }

  .home-container > .to-give-ogun > .box {
    width: 100%;
  }

  .home-container > .to-give-ogun > .box > p {
    color: #fff;
    text-align: center;
    font-family: Campton;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.44px;
  }
}

.home-container > .home-speech {
  width: 100%;
  height: 786px;
  display: flex;
  justify-content: space-between;
  border: 1px solid rgba(0, 0, 0, 0.3);
  background: #fff;
  overflow: hidden;
}

.home-container > .home-speech > .box1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 100px 0px 0px 80px;
}

.home-container > .home-speech > .box1 > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.76px;
}

.home-container > .home-speech > .box1 > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home-container > .home-speech > .box2 {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
}

.home-container > .home-speech > .box2 > .box {
  width: 100%;
  height: 439px;
  flex-shrink: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.3);
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  background: #fff;
  padding: 34px 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.home-container > .home-speech > .box2 > .box > .wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.home-container > .home-speech > .box2 > .box > .wrap > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.64px;
  z-index: 1;
}

.home-container > .home-speech > .box2 > .box > .wrap > p {
  color: #202020;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 132.1%;
  z-index: 1;
}

.home-container > .home-speech > .box2 > .box > svg {
  z-index: 1;
  transition: 0.3s ease-in-out;
}

.home-container > .home-speech > .box2 > .box > .wrap > span {
  color: rgba(32, 32, 32, 0.5);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 132.1%;
  z-index: 1;
}

.home-container > .home-speech > .box2 > .box > .overlay {
  position: absolute;
  top: 0%;
  left: -100%;
  width: 100%;
  height: 100%;
  background: rgba(238, 235, 235, 0.7);
  transition: 0.3s ease-in-out;
  display: flex;
  align-items: flex-end;
  padding: 30px 30px 50px 30px;
}

.home-container > .home-speech > .box2 > .box > .overlay > a {
  color: #2b9a5f;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 130.4%;
  text-decoration: none;
}

.home-container > .home-speech > .box2 > .box:hover > svg {
  margin-left: 100px;
}

.home-container > .home-speech > .box2 > .box:hover > .overlay {
  left: 0%;
}

@media screen and (max-width: 896px) {
  .home-container > .home-speech {
    flex-direction: column;
    height: fit-content;
  }

  .home-container > .home-speech > .box1 {
    padding: 0%;
    height: 257px;
    justify-content: center;
    padding: 16px;
  }

  .home-container > .home-speech > .box2 {
    overflow: visible;
    height: fit-content;
  }

  .home-container > .home-speech > .box1 > h3 {
    color: #202020;
    font-family: Campton;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.48px;
  }

  .home-container > .home-speech > .box1 > p {
    color: #202020;
    font-family: Montserrat;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .home-container > .home-speech > .box2 > .box {
    padding: 16px;
  }
  .home-container > .home-speech > .box2 > .box > .wrap > h3 {
    color: #202020;
    font-family: Campton;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
  }

  .home-container > .home-speech > .box2 > .box > .overlay {
    padding: 30px;
  }

  .home-container > .home-speech > .box2 > .box:hover > svg {
    margin-left: 120px;
  }
}

.home-container > .back-attach {
  width: 100%;
  height: 960px;
  background-image: url("https://i.ibb.co/FX2bFS9/DSC02279-1-2x.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

@media screen and (max-width: 896px) {
  .home-container > .back-attach {
    background-attachment: scroll;
    background-size: cover;
    background-image: url("https://i.ibb.co/fCvZC84/Frame-9.png");
  }
}

.home-container > .from-promise {
  width: 100%;
  padding: 150px 0px 150px 80px;
}

.home-container > .from-promise > .from {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 80px;
  margin-bottom: 80px;
}

.home-container > .from-promise > .from > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.home-container > .from-promise > .from > button {
  color: #2b9a5f;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

@media screen and (max-width: 896px) {
  .home-container > .from-promise {
    padding: 16px;
  }
  .home-container > .from-promise > .from {
    flex-direction: column;
    align-items: start;
    gap: 20px;
    height: 476px;
    justify-content: center;
  }

  .home-container > .from-promise > .from > h3 {
    color: #202020;
    font-family: Campton;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.home-container > .home-invest {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
}

.home-container > .home-invest > .box1 {
  width: 100%;
  height: 626px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 80px;
}

.home-container > .home-invest > .box1 > .header {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.home-container > .home-invest > .box1 > .header > span {
  width: 30px;
  height: 2px;
  background-color: #2b9a5f;
}

.home-container > .home-invest > .box1 > .header > p {
  color: #2b9a5f;
  font-family: Campton;
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.home-container > .home-invest > .box1 > .inner {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 50px 0px;
  padding-left: 22px;
}

.home-container > .home-invest > .box1 > .inner > p {
  color: rgba(32, 32, 32, 0.9);
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.4%;
  width: 570px;
}

.home-container > .home-invest > .box1 > a {
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  color: #202020;
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 15px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.6);
  text-decoration: none;
  margin-left: 22px;
}

.home-container > .home-invest > .box2 {
  width: 100%;
  /* height: 626px; */
  position: relative;

}



.home-container > .home-invest > .box2 > .inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 99%;
  padding: 40px 49px;
  display: flex;
  justify-content: space-between;
  flex-direction: column; ;
  background-color: rgba(18, 18, 18, 0.5);
  color: #fff;
  z-index: 1;
}

.home-container > .home-invest > .box2 > .inner > p {
  width: 216px;
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 130.4%;
}

.home-container > .home-invest > .box2 > .inner > .in {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-container > .home-invest > .box2 > .inner > .in > p {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130.4%;
  letter-spacing: 0.84px;
  z-index: 2;
}

.home-container > .home-invest > .box2 > .inner > .in > span {
  color: #fff;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 500;
  line-height: 130.4%;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
}

@media screen and (max-width: 896px) {
  .home-container > .home-invest {
    flex-direction: column-reverse;
    height: fit-content;
  }

  .home-container > .home-invest > .box1 {
    padding: 16px;
  }

  .home-container > .home-invest > .box1 > .inner > p {
    width: 100%;
    padding: 0;
  }

  .home-container > .home-invest > .box1 > .header > span {
    display: none;
  }
  .home-container > .home-invest > .box1 > .inner {
    padding: 0%;
  }

  .home-container > .home-invest > .box1 > a {
    margin: 0%;
  }
  .home-container > .home-invest > .box2 > .inner {
    padding: 16px;
  }
}

.home-container > .news-home {
  width: 100%;
  padding: 150px 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 50px;
}

.home-container > .news-home > button {
  display: none;
}

.home-container > .news-home > .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.home-container > .news-home > .header > h1 {
  color: #202020;
  font-family: Campton;
  font-size: 38px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.home-container > .news-home > .header > button {
  display: flex;
  padding: 10px 15px;
  align-items: center;
  gap: 10px;
  border-radius: 40px;
  background: #fecb00;
  color: #121212;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border: none;
  outline: none;
  cursor: pointer;
}

.home-container > .news-home > .content-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.home-container > .news-home > .content-container > .box {
  width: 420px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  text-decoration: none;
}

.home-container > .news-home > .content-container > .box > img {
  width: 100%;
  height: 280px;
  object-fit: cover;
}

.home-container > .news-home > .content-container > .box > h3 {
  color: #000;
  font-family: Campton;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.home-container > .news-home > .content-container > .box > p {
  color: #000;
  text-overflow: ellipsis;
  font-family: Montserrat;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}


.home-container > .news-home > .content-container > .box > button {
  background-color: transparent;
  color: #1b3126;
  font-family: Montserrat;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-decoration-line: underline;
  width: fit-content;
  border: none;
  outline: none;
}

@media screen and (max-width: 896px) {
  .home-container > .news-home {
    flex-direction: column;
    padding: 16px;
    margin-bottom: 50px;
  }
  .home-container > .news-home > .content-container {
    flex-direction: column;
  }
  .home-container > .news-home > .header > button {
    display: none;
  }
  .home-container > .news-home > .header > h1 {
    color: #202020;
    font-family: Campton;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .home-container > .news-home > button {
    display: block;
    display: flex;
    padding: 10px 15px;
    align-items: center;
    gap: 10px;
    border-radius: 40px;
    background: var(--yellow, #fecb00);
    color: #121212;
    font-family: Montserrat;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border: none;
    outline: none;
    cursor: pointer;
  }

  .home-container > .news-home > .content-container > .box {
    width: 100%;
  }
}

.home-container > .home-contact {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.home-container > .home-contact > .box1 {
  width: 100%;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 75px 80px;
  background: #fffae7;
  overflow: hidden;
  position: relative;
}

.home-container > .home-contact > .box1 > .overlay {
  position: absolute;
  bottom: -100%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #76bd64;
  transition: 0.3s ease-in-out;
}

.home-container > .home-contact > .box1:hover > .overlay {
  bottom: 0%;
}

.home-container > .home-contact > .box1 > .header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.home-container > .home-contact > .box1 > .header > h3 {
  width: 239px;
  color: #000;
  font-family: Campton;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 2;
}

.home-container > .home-contact > .box1 > .header > p {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 2;
}

.home-container > .home-contact > .box1 > a {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  border-bottom: 1px solid #000;
  width: fit-content;
  z-index: 2;
}

.home-container > .home-contact > .box2 {
  width: 100%;
  height: 580px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 75px 80px;
  background: #a5da98;
  overflow: hidden;
  position: relative;
}

.home-container > .home-contact > .box2 > .overlay {
  position: absolute;
  bottom: -100%;
  left: 0%;
  width: 100%;
  height: 100%;
  background-color: #254a38;
  transition: 0.3s ease-in-out;
}

.home-container > .home-contact > .box2:hover > .overlay {
  bottom: 0%;
}

.home-container > .home-contact > .box2 > .header {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.home-container > .home-contact > .box2 > .header > h3 {
  color: #000;
  font-family: Campton;
  font-size: 26px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  width: 413px;
  z-index: 2;
}

.home-container > .home-contact > .box2:hover > .header > h3 {
  color: white;
  transition: 0.5s ease-in-out;
}

.home-container > .home-contact > .box2 > .header > .icon {
  width: 24px;
  height: 24px;
}

.home-container > .home-contact > .box2:hover > .header > .icon {
  transition: 0.5s ease-in-out;
  z-index: 2;
  color: white;
}

.home-container > .home-contact > .box2 > .header > p {
  color: #000;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  z-index: 2;
}

.home-container > .home-contact > .box2:hover > .header > p {
  color: white;
  transition: 0.5s ease-in-out;
}

.home-container > .home-contact > .box2 > a {
  color: #000;
  font-family: Montserrat;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  border-bottom: 1px solid #000;
  width: fit-content;
  z-index: 2;
}

.home-container > .home-contact > .box2 > a > .icon {
  width: 18px;
  height: 18px;
}

.home-container > .home-contact > .box2:hover > a {
  transition: 0.3s ease-in-out;
  color: white;
  border-bottom: 1px solid white;
}

.home-container > .home-contact > .box2:hover > a > .icon {
  transition: 0.3s ease-in-out;
  color: white;
}

@media screen and (max-width: 896px) {
  .home-container > .home-contact {
    width: 100%;
    flex-direction: column;
  }

  .home-container > .home-contact > .box1,
  .home-container > .home-contact > .box2 {
    padding: 20px 16px;
  }
}
