.about-slider-middle {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: hidden;
}

.about-slider-middle > .btn > svg {
  cursor: pointer;
}

.about-slider-middle > .scrolling {
  width: 100%;
  height: 500px;
  display: flex;
  gap: 20px;
  overflow-x: auto;
  overflow-y: hidden;
  padding-right: 10px;
  scroll-behavior: smooth;
  transition: scroll-left 0.2s ease;
}

.about-slider-middle > .scrolling {
  scrollbar-width: none;
}
.about-slider-middle > .scrolling::-webkit-scrollbar {
  display: none;
}

.about-slider-middle > .scrolling > .box {
  width: 500px;
  height: 500px;
  flex-shrink: 0;
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  position: relative;
}

.about-slider-middle > .scrolling > .box > h3 {
  color: #202020;
  font-family: Campton;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.about-slider-middle > .scrolling > .box:nth-child(1) {
  background: #cea608;
}

.about-slider-middle > .scrolling > .box:nth-child(1) > h3,
.about-slider-middle > .scrolling > .box:nth-child(5) > h3 {
  color: #fff;
  font-family: Campton;
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.about-slider-middle > .scrolling > .box > .below {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.about-slider-middle > .scrolling > .box > img {
  width: 100%;
  position: absolute;
  top: 0%;
  left: 0;
  transition: 0.3s ease-in-out;
  object-fit: contain;
}

.about-slider-middle > .scrolling > .box > .below > h3 {
  color: #fff;
  font-family: Campton;
  font-size: 26px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  transition: 0.3s ease-in-out;
  z-index: 100;
}

@media screen and (max-width: 768px) {
  .about-slider-middle > .scrolling > .box {
    width: 90%;
    height: 330px;
    padding: 20px;
  }

  .about-slider-middle > .scrolling {
    height: fit-content;
  }
}
